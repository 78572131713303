import * as yup from "yup"

export const schema = yup
  .object()
  .shape({
    name: yup.string().trim().required("Please enter your name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    message: yup.string().trim().required("Please enter your name"),
  })
  .required()
