import React, { useState } from "react"

import { SubmitHandler, useForm } from "react-hook-form"
import FormLayout from "../../components/FormLayout/FormLayout"
import { yupResolver } from "@hookform/resolvers/yup"
import { ContactUsInputs } from "../../../types/pages/contact-us"
import * as styles from "./ContactUs.module.scss"
import Seo from "../../components/Seo/seo"
import { CustomTextInput } from "../../components/CustomTextInput/CustomTextInput"
import { CustomTextAreaInput } from "../../components/CustomTextAreaInput/CustomTextAreaInput"
import { ProgressBar } from "../../components/ProgressBar/ProgressBar"
import { schema } from "../../validation/signups/contactUs"
import { fromJSON } from "../../lib/form-data"
import { navigate } from "gatsby"

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsInputs>({
    resolver: yupResolver(schema),
    mode: "all",
  })
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<ContactUsInputs> = async data => {
    try {
      setIsLoading(true)
      const uri = new URL("/contact-us", process.env.GATSBY_API_URL).toString()
      const formData = fromJSON(data)

      const response = await fetch(uri, {
        method: "POST",
        body: formData,
      })
      if (response.ok) {
        navigate("/sign-up/confirmation?contactus")
      } else {
        const res = await response.json()
        console.error(res)
      }
    } catch (ex) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Seo title="Contact Us" />
      {isLoading && <ProgressBar />}
      <div>
        <FormLayout
          title="Contact Us"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.contactUsForm}
          backgroundClassName={styles.contactUsFormBackground}
        >
          <div className={styles.contactUs}>
            <label>
              <span>Name</span>
            </label>
            <CustomTextInput
              errorMessage={errors?.name?.message}
              placeholder="Name"
              register={register("name")}
            />
            <label>
              <span>Email</span>
            </label>
            <CustomTextInput
              errorMessage={errors?.email?.message}
              placeholder="abc@xyz.com"
              register={register("email")}
            />
            <label>
              <div className={styles.contactUsLabelInner}>
                <span>Phone Number</span>
                <span>Optional</span>
              </div>
            </label>
            <CustomTextInput
              errorMessage={errors?.phoneNumber?.message}
              placeholder="(222)-222-2222"
              register={register("phoneNumber")}
            />
            <label>
              <span>Message</span>
            </label>
            <CustomTextAreaInput
              errorMessage={errors?.message?.message}
              placeholder="Write a short-brief description here"
              register={register("message")}
            />

            <div className={styles.contactUsButton}>
              <button
                type="submit"
                disabled={Object.keys(errors).length > 0 || isLoading}
              >
                Send
              </button>
              <span className={styles.contactUsInfo}>
                <span>Alternatively, send us an email at</span>
                <span className={styles.contactUsInfoBold}>
                  info@digitalartfair.io
                </span>
              </span>
            </div>
          </div>
        </FormLayout>
      </div>
    </>
  )
}

export default ContactUs
