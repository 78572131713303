import React from "react"
import * as styles from "./CustomTextAreaInput.module.scss"
import warningRed from "../../images/warningRed.png"
import Textarea from "../Textarea/Textarea"

interface CustomTextAreaInputProps {
  errorMessage?: string
  register: any
  placeholder: string
}

export const CustomTextAreaInput = ({
  errorMessage,
  register,
  placeholder,
}: CustomTextAreaInputProps) => {
  return (
    <div className={styles.CustomTextInputcontainer}>
      <div className={errorMessage ? styles.withError : ""}>
        <textarea placeholder={errorMessage ?? placeholder} {...register} />
        <img src={warningRed} alt="Warning" />
      </div>
    </div>
  )
}
